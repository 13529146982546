import React, {Fragment, useContext, useState} from 'react';
import styled from 'styled-components';
import {Context} from '../AppContext';
import {formatValidator} from '../Utils';
import invoiceValidator from '../Utils/invoice-validator';
import {Alert, message} from 'antd';
import {Check} from '@styled-icons/material';
import {ErrCustomOrder, ErrInvoiceFormat} from '../errors';
import {
  Heading,
  Row,
  Text,
  Label,
  Input as WidgetInput,
  Select,
  SelectOption,
  TextArea,
  Checkbox,
  Button,
  Divider,
} from '../Widgets';

const {isNotEmpty, isEmail, isMobileNumber} = formatValidator;

function errorHandler(err) {
  console.warn(err);
  if (err instanceof ErrCustomOrder || err instanceof ErrInvoiceFormat) {
    message.error(err.message);
  } else {
    message.error('發生錯誤');
  }
}

const defaultValues = {
  note: '',
  quantity: 1,
  amount: 100,
  logistic_fee: 0,
  config: {
    name: '',
    email: '',
    phone: '',
    use_bonus: false,
    invoiceConfig: {
      invoice_type: 'two_copies',
      invoice_subtype: 'ezpay_vehicle',
      mobile_vehicle_code: '',
      citizen_personal_certificate_code: '',
      company_title: '',
      gui_number: '',
      donate_foundation_gui: '',
      love_code: '',
    },
    deliveryConfig: {
      delivery_type: 'self_pick',
      is_delivery_private: false,
      receiver_address: '',
      receiver_name: '',
      receiver_phone: '',
      receiver_tel: '',
      zip: '',
      sender_address: '',
      sender_name: '',
      sender_phone: '',
      sender_tel: '',
      sender_zip: '',
    },
  },
};

const Input = styled(WidgetInput)`
  flex-basis: auto;
  margin: 0;
`;

function Customer({customer, onClick, selected}) {
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: 8,
        margin: 8,
        borderBottom: '1px solid grey',
      }}>
      <div style={{width: 40}}>
        {selected && <Check size={24} color="black" />}
      </div>
      <div>
        <div>姓名: {customer.name || '---'}</div>
        <div>電話: {customer.phone || '---'}</div>
        <div>信箱: {customer.email || '---'}</div>
      </div>
    </div>
  );
}

export default function CustomOrderModal() {
  const app = useContext(Context);
  const [values, _setValues] = useState(defaultValues);
  const [customers, setCustomers] = useState([]);
  const [buyer, setBuyer] = useState();
  const [calculations, setCalculations] = useState();

  function setValues(obj) {
    _setValues((pre) => ({...pre, ...obj}));
  }

  function setConfig(obj) {
    _setValues((pre) => ({
      ...pre,
      config: {
        ...pre.config,
        ...obj,
      },
    }));
  }

  const renderInvoiceField = (values) => ({label, field}) => {
    return (
      <div>
        <Label>{label}</Label>
        <Row>
          <Input
            value={values.config.invoiceConfig[field]}
            onChange={(e) =>
              setConfig({
                invoiceConfig: {
                  ...values.config.invoiceConfig,
                  [field]: e.target.value,
                },
              })
            }
          />
        </Row>
      </div>
    );
  };

  const renderDeliveryField = (values) => ({label, field}) => {
    return (
      <div>
        <Label>{label}</Label>
        <Row>
          <Input
            value={values.config.deliveryConfig[field]}
            onChange={(e) =>
              setConfig({
                deliveryConfig: {
                  ...values.config.deliveryConfig,
                  [field]: e.target.value,
                },
              })
            }
          />
        </Row>
      </div>
    );
  };

  const setUseBonus = async (value) => {
    const {note, quantity, amount, config, logistic_fee} = values;

    if (!value) {
      setConfig({use_bonus: value});
    } else {
      app.actions.setLoading(true);
      try {
        if (!isNotEmpty(note)) {
          throw new ErrCustomOrder('商品名稱及備註為必填');
        }
        if (!buyer) {
          throw new ErrCustomOrder('請選擇顧客');
        }

        let resp = await app.actions.getCustomCalculations({
          item_amount: amount,
          item_quantity: quantity,
          logistic_fee,
          note,
          buyer: buyer?.id,
          config: {
            ...config,
            use_bonus: true,
          },
        });
        setCalculations(resp);
        setConfig({use_bonus: value});
      } catch (err) {
        setConfig({use_bonus: false});
        errorHandler(err);
      }
      app.actions.setLoading(false);
    }
  };

  const searchCustomer = async (search) => {
    if (!search) {
      return;
    }

    app.actions.setLoading(true);
    setCustomers([]);
    setBuyer(null);

    try {
      let resp = await app.actions.getUsers({search});
      setCustomers(resp);
    } catch (ex) {
      // bypass
    }

    app.actions.setLoading(false);
  };

  const valid = () => {
    const {note, quantity, amount, config} = values;
    const {name, phone, email, deliveryConfig, invoiceConfig} = config;
    const {
      delivery_type,
      is_delivery_private = '',
      receiver_name = '',
      receiver_phone = '',
      receiver_address = '',
      sender_name = '',
      sender_phone = '',
      sender_address = '',
    } = deliveryConfig;

    if (!note) {
      throw new ErrCustomOrder(`訂製單商品都需填寫`);
    }

    if (!quantity) {
      throw new ErrCustomOrder(`訂製單數量需填寫`);
    }
    if (!amount) {
      throw new ErrCustomOrder(`訂製單價錢都需填寫`);
    }

    if (!buyer) {
      throw new ErrCustomOrder('顧客資訊請先從搜尋結果選擇');
    }

    // user config
    if (!isNotEmpty(name) || !isNotEmpty(email) || !isNotEmpty(phone)) {
      throw new ErrCustomOrder('請填入會員資料必填欄位');
    }
    if (!isEmail(email)) {
      throw new ErrCustomOrder('會員資料電子信箱格式錯誤');
    }
    if (!isMobileNumber(phone)) {
      throw new ErrCustomOrder('會員資料手機格式錯誤');
    }

    //delivery config
    if (delivery_type === 'hct') {
      if (
        !isNotEmpty(receiver_name) ||
        !isNotEmpty(receiver_phone) ||
        !isNotEmpty(receiver_address)
      ) {
        throw new ErrCustomOrder('請填入宅配運送/收件人必填欄位');
      }
      if (!isMobileNumber(receiver_phone)) {
        throw new ErrCustomOrder('收件人手機格式錯誤');
      }
    } else {
      // self pick
    }

    if (is_delivery_private) {
      /* 保密代記 */
      if (
        !isNotEmpty(sender_name) ||
        !isNotEmpty(sender_phone) ||
        !isNotEmpty(sender_address)
      ) {
        throw new ErrCustomOrder('請填入保密代寄/寄件人必填欄位');
      }
      if (!isMobileNumber(sender_phone)) {
        throw new ErrCustomOrder('保密代寄/寄件人手機格式錯誤');
      }

      if (
        sender_name === receiver_name ||
        sender_phone === receiver_phone ||
        sender_address === receiver_address
      ) {
        throw new ErrCustomOrder('保密代寄/寄件人資料不可和收件人資料相同');
      }
    }

    // invoice config
    invoiceValidator(invoiceConfig);
  };

  const submit = async () => {
    const {note, quantity, amount, config, logistic_fee} = values;

    app.actions.setLoading(true);
    try {
      valid();

      await app.actions.createCustomOrder({
        item_amount: parseInt(amount),
        item_quantity: parseInt(quantity),
        logistic_fee: parseInt(logistic_fee),
        note,
        buyer: buyer.id,
        config,
      });

      message.success('創建訂單成功！');
      app.actions.setModal();
    } catch (ex) {
      errorHandler(ex);
    }
    app.actions.setLoading(false);
  };

  return (
    <div>
      <Divider paddingSize="lg" position="left">
        <Heading size="sm">商品資訊</Heading>
      </Divider>

      <Label>商品名稱及備註</Label>
      <Row>
        <TextArea
          value={values.note}
          onChange={(e) => setValues({note: e.target.value})}
        />
      </Row>

      <Label>數量</Label>
      <Row>
        <Input
          type="number"
          value={values.quantity}
          onChange={(e) => {
            setValues({quantity: e.target.value});
            setConfig({use_bonus: false});
          }}
        />
      </Row>

      <Label>價格（未稅）</Label>
      <Row>
        <Input
          type="number"
          value={values.amount}
          onChange={(e) => {
            setValues({amount: e.target.value});
            setConfig({use_bonus: false});
          }}
        />
      </Row>

      {values.config.deliveryConfig.delivery_type !== 'self_pick' && (
        <Fragment>
          <Label>運費（未稅）</Label>
          <Row>
            <Input
              type="number"
              value={values.logistic_fee}
              onChange={(e) => setValues({logistic_fee: e.target.value})}
            />
          </Row>

          <Alert
            message="若有運費才需填寫，預設為0"
            type="info"
            style={{marginBottom: 10}}
          />
        </Fragment>
      )}

      <Divider position="left">
        <Heading size="sm">顧客資訊</Heading>
      </Divider>
      <Alert
        message="請先由搜尋結果選擇，訂單需對應存在的顧客資訊"
        type="info"
        style={{marginBottom: 10}}
      />

      <Row>
        <Input.Search
          placeholder="請輸入姓名,電話,或信箱"
          onSearch={searchCustomer}
          enterButton="搜尋顧客"
        />
      </Row>

      {buyer && (
        <Customer
          key={'selected'}
          customer={buyer}
          selected={true}
          onClick={() => {
            setBuyer(null);
            setConfig({
              name: '',
              phone: '',
              email: '',
              use_bonus: false,
            });
          }}
        />
      )}

      {!buyer &&
        customers.map((customer) => (
          <Customer
            key={customer.id}
            customer={customer}
            selected={false}
            onClick={() => {
              setBuyer(customer);
              setConfig({
                name: customer.name,
                phone: customer.phone,
                email: customer.email,
                use_bonus: false,
              });
            }}
          />
        ))}

      <Label>姓名</Label>
      <Row>
        <Input
          value={values.config.name}
          onChange={(e) => setConfig({name: e.target.value})}
        />
      </Row>

      <Label>電話</Label>
      <Row>
        <Input
          value={values.config.phone}
          onChange={(e) => setConfig({phone: e.target.value})}
        />
      </Row>

      <Label>EMAIL</Label>
      <Row>
        <Input
          value={values.config.email}
          onChange={(e) => setConfig({email: e.target.value})}
        />
      </Row>

      <Divider paddingSize="lg" position="left">
        <Heading size="sm">發票資訊</Heading>
      </Divider>
      <Select
        style={{marginBottom: 10}}
        value={values.config.invoiceConfig.invoice_type}
        onChange={(value) =>
          setConfig({
            invoiceConfig: {
              ...values.config.invoiceConfig,
              invoice_type: value,
            },
          })
        }>
        <SelectOption value="two_copies">二聯式</SelectOption>
        <SelectOption value="three_copies">三聯式</SelectOption>
        <SelectOption value="donate">捐贈</SelectOption>
      </Select>

      {values.config.invoiceConfig.invoice_type === 'two_copies' && (
        <>
          <Row>
            <Select
              style={{
                flexBasis: 200,
              }}
              value={values.config.invoiceConfig.invoice_subtype}
              onChange={(value) =>
                setConfig({
                  invoiceConfig: {
                    ...values.config.invoiceConfig,
                    invoice_subtype: value,
                  },
                })
              }>
              <SelectOption value="citizen_personal_certificate">
                自然人憑證條碼
              </SelectOption>
              <SelectOption value="mobile_vehicle">手機載具</SelectOption>
              <SelectOption value="ezpay_vehicle">
                ezPay 電子發票載具
              </SelectOption>
            </Select>
          </Row>

          {values.config.invoiceConfig.invoice_subtype ===
            'citizen_personal_certificate' &&
            renderInvoiceField(values)({
              label: '自然人憑證條碼16碼',
              field: 'citizen_personal_certificate_code',
            })}

          {values.config.invoiceConfig.invoice_subtype === 'mobile_vehicle' &&
            renderInvoiceField(values)({
              label: '手機載具條碼8碼',
              field: 'mobile_vehicle_code',
            })}
        </>
      )}

      {values.config.invoiceConfig.invoice_type === 'three_copies' &&
        renderInvoiceField(values)({
          label: '公司名稱',
          field: 'company_title',
        })}

      {values.config.invoiceConfig.invoice_type === 'three_copies' &&
        renderInvoiceField(values)({
          label: '統一編號',
          field: 'gui_number',
        })}

      {/* {values.config.invoiceConfig.invoice_type === "donate" &&
          renderInvoiceField(values)({
            label: "捐贈單位統編",
            field: "donate_foundation_gui",
          })} */}

      {values.config.invoiceConfig.invoice_type === 'donate' &&
        renderInvoiceField(values)({
          label: '愛心碼',
          field: 'love_code',
        })}

      <Divider paddingSize="lg" position="left">
        <Heading size="sm">收件方式</Heading>
      </Divider>
      <Select
        style={{marginBottom: 10}}
        value={values.config.deliveryConfig.delivery_type}
        onChange={(value) => {
          setConfig({
            deliveryConfig: {
              ...values.config.deliveryConfig,
              delivery_type: value,
            },
          });
          setValues({
            ...(value === 'self_pick' && {logistic_fee: 0}),
          });
        }}>
        <SelectOption value="self_pick">自取</SelectOption>
        <SelectOption value="hct">宅配</SelectOption>
      </Select>

      {values.config.deliveryConfig.delivery_type === 'hct' && (
        <>
          <Divider position="left">
            <Heading size="sm">收件人資訊</Heading>
          </Divider>
          {renderDeliveryField(values)({
            label: '收件人地址',
            field: 'receiver_address',
          })}
          {renderDeliveryField(values)({
            label: '收件人郵遞區號',
            field: 'zip',
          })}
          {renderDeliveryField(values)({
            label: '收件人姓名',
            field: 'receiver_name',
          })}
          {renderDeliveryField(values)({
            label: '收件人手機',
            field: 'receiver_phone',
          })}
          {renderDeliveryField(values)({
            label: '收件人市話',
            field: 'receiver_tel',
          })}

          <div>
            <Checkbox
              checked={values.config.deliveryConfig.is_delivery_private}
              onChange={(e) => {
                setConfig({
                  deliveryConfig: {
                    ...values.config.deliveryConfig,
                    is_delivery_private: e.target.checked,
                  },
                });
              }}>
              保密代寄
            </Checkbox>
          </div>

          {values.config.deliveryConfig.is_delivery_private && (
            <>
              <Divider position="left">
                <Heading size="sm">寄件人資訊</Heading>
              </Divider>
              {renderDeliveryField(values)({
                label: '寄件人地址',
                field: 'sender_address',
              })}
              {renderDeliveryField(values)({
                label: '寄件人郵遞區號',
                field: 'sender_zip',
              })}
              {renderDeliveryField(values)({
                label: '寄件人姓名',
                field: 'sender_name',
              })}
              {renderDeliveryField(values)({
                label: '寄件人手機',
                field: 'sender_phone',
              })}
              {renderDeliveryField(values)({
                label: '寄件人市話',
                field: 'sender_tel',
              })}
            </>
          )}
        </>
      )}
      <Divider paddingSize="lg" position="left" />

      <Row>
        <Checkbox
          checked={values.config.use_bonus}
          onChange={(e) => setUseBonus(e.target.checked)}>
          使用紅利折抵
        </Checkbox>
        {values.config.use_bonus && calculations && (
          <Text size="sm" style={{marginLeft: 10}}>
            {' '}
            可折抵紅利(未稅)：{`${calculations.bonus}`}{' '}
          </Text>
        )}
      </Row>

      <Row style={{justifyContent: 'flex-end'}}>
        <Button onClick={submit}>送出訂單</Button>
      </Row>
    </div>
  );
}

const metadata = {
  title: <Heading align="center">訂製商品</Heading>,
  width: '600px',
};

export {metadata};
