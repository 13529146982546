import React, {useContext, Fragment, useState} from 'react';
import {Context} from '../../AppContext';
import UnAuthResult from '../../Components/UnAuthResult';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import OrderTable from '../../Components/OrderTable';
import ORDER_TABLE_TYPES from '../../Components/OrderTable/orderTableTypes';
import {Row, Button} from '../../Widgets';
import CustomOrderModal, {
  metadata as modalMetadata,
} from '../../Modals/CustomOrderModal';
import {
  Breadcrumb,
  TabContainer,
  Information,
  Navbar,
} from '../../Components/ProfileLayout';
import useProfileLayout from '../../hooks/use-profile-layout';
import {Row as AntRow, Col} from 'antd';

export default function OrderList(props) {
  const app = useContext(Context);
  const {profile} = app.state;
  const [search, setSearch] = useState('');
  const pathname = props.location.pathname;
  const {selected, setSelected, parent} = useProfileLayout({pathname});

  if (!profile) {
    return <UnAuthResult />;
  }

  return (
    <ContentWrapper>
      <AntRow gutter={20}>
        <Col md={4} xs={24}>
          <Navbar pathname={pathname} />
        </Col>
        <Col md={1} xs={24} style={{height: 20}}></Col>
        <Col md={19} xs={24}>
          <Breadcrumb entry={parent} leafNode={selected} />
          <Information />
          <Row>
            <div style={{flex: 1}} />
            {/* <Input.Search 
            allowClear
            onSearch={value => this.setState({ search: value })}
            placeholder="搜尋訂單編號"
            style={{ width: 300, marginBottom: 10 }}
            /> */}
            {profile.staff_type === 'staff' && (
              <Button
                onClick={() =>
                  app.actions.setModal({
                    content: <CustomOrderModal />,
                    ...modalMetadata,
                  })
                }>
                自訂商品
              </Button>
            )}
          </Row>
          <TabContainer
            entry={parent}
            leafNode={selected}
            onTabClick={(entry) => setSelected(entry)}>
            {(() => {
              switch (selected.name) {
                case 'order':
                  return (
                    <OrderTable
                      type={ORDER_TABLE_TYPES.NORMAL}
                      search={search}
                    />
                  );
                case 'orderWaiting':
                  return (
                    <OrderTable
                      type={ORDER_TABLE_TYPES.WAITING_OR_PROCESSING}
                      search={search}
                    />
                  );

                case 'orderItemRejected':
                  return (
                    <OrderTable
                      type={ORDER_TABLE_TYPES.ORDER_ITEM_REJECTED}
                      search={search}
                    />
                  );

                case 'orderFinished':
                  return (
                    <OrderTable
                      type={ORDER_TABLE_TYPES.SUCCESS}
                      search={search}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </TabContainer>
        </Col>
      </AntRow>
    </ContentWrapper>
  );
}
